import styled from "styled-components"
import { device } from "../Common/device"
import { Navbar, NavDropdown } from "react-bootstrap"

export const NavbarCustom = styled(Navbar)`
  background: ${props => (props.isSticky ? "#ffffff" : "#ffffff00")} !important;
  padding: 0px !important;
  box-shadow: ${props =>
    props.isSticky ? "0px 1px 11px -1px #d6d6d6" : "none"};

  @media ${device.laptopN} {
    .navbar-collapse {
      background: #fff !important;
      padding: 10px !important;
    }
  }
  @media ${device.mobileXL} {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  //Scrollspy ul styles
  ul {
    margin: 0;
  }

  li {
    padding: 15px 20px;
    margin: 0;
    display: inline-flex;

    @media ${device.laptopN} {
      display: block;
    }
  }

  //Anchor Link - Selected Menu Item
  .is-current a {
    color: #69b051 !important;

    @media ${device.laptopN} {
      border: none;
      color: #69b051;
    }
  }

  //Anchor Link Styles
  a {
    padding: 0px 0px 10px;
    color: ${props => (props.isSticky ? "#494949" : "#ffffff")};

    :hover {
      text-decoration: none;
      color: #69b051;
    }

    @media ${device.laptopN} {
      color: #494949;
    }
  }

  //Logo
  .navbar-brand {
    padding: 0px;

    @media ${device.laptopN} {
      padding: 15px;
    }
  }

  .dropdwn {
    a {
      color: #494949;
      padding-left: 10px;
      padding-right: 10px;
      white-space: nowrap;
      display: block;

      :hover {
        text-decoration: none;
        color: #69b051;
      }

      @media ${device.laptopN} {
        color: #494949;
      }
    }
  }
  .dropbtn {
    color: ${props => (props.isSticky ? "#494949" : "#ffffff")};
    @media ${device.laptopN} {
      color: #494949;
    }
  }
  #test {
    color: ${props => (props.isSticky ? "#494949" : "#ffffff")};
    @media ${device.laptopN} {
      color: #494949;
    }
  }
  .contactBtn {
    background: #69b051;
    color: #fff;
    padding: 12px 30px !important;
    text-decoration: none;
    cursor: pointer;

    :hover {
      background: #5bcd34;
      border: none;
      color: #fff;
    }
  }

  .is-current .contactBtn {
    color: #fff;
  }
`

export const BrandLogo = styled.img`
  margin-bottom: 0px;
  height: ${props => (props.isSticky ? "80px" : "75px")};
  max-width: ${props => (props.isSticky ? "190px" : "190px")};
  transition: all 0.5s;

  @media ${device.mobileM} {
    max-width: 145px;
  }
  @media ${device.tablet} {
    height: 60px;
  }
`

export const NavbarToggleCustom = styled(Navbar.Toggle)`
    outline:0px !important;

    border-color:${props =>
      props.isSticky ? "rgba(0,0,0,.1)" : "rgba(255,255,255,.1)"} !important;
    .navbar-toggler-icon{
    background-image:url("${props =>
      props.isSticky
        ? "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"
        : "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"}") !important;
    }
`
