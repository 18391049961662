import React, { Component } from "react"
import {
  NavbarCustom,
  NavbarToggleCustom,
  CustomLink,
  BrandLogo,
} from "./headermenubs.style"
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap"

import "bootstrap/dist/css/bootstrap.min.css"
import { StaticQuery, graphql, Link } from "gatsby"
import ScrollSpy from "react-scrollspy"
import AnchorLink from "react-anchor-link-smooth-scroll"

class Headermenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyClass: "top",
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let stickyClass = "topSticky"
      let scrollPos = window.scrollY

      if (scrollPos < 100) {
        stickyClass = "top"
      }

      if (this.state.stickyClass !== stickyClass) {
        this.setState({ stickyClass })
      }
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", null)
  }

  render() {
    return (
      <NavbarCustom
        expand="xl"
        fixed="top"
        isSticky={
          this.props.home === false
            ? true
            : this.state.stickyClass === "topSticky"
            ? true
            : false
        }
      >
        <Container>
          <Link to="/">
            <BrandLogo
              src={
                this.state.stickyClass === "topSticky" || !this.props.home
                  ? this.props.HeaderData.BrandLogoSticky
                  : this.props.HeaderData.BrandLogo
              }
              isSticky={this.state.stickyClass === "topSticky" ? true : false}
              alt="Logo"
            />
          </Link>

          <NavbarToggleCustom
            isSticky={
              this.props.home === false
                ? true
                : this.state.stickyClass === "topSticky"
                ? true
                : false
            }
            aria-controls="basic-navbar-nav"
          />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <ScrollSpy offset={-59} items={this.props.HeaderData.Items}>
                {this.props.HeaderData.MenuItems.map((menuItem, idx) => {
                  if (idx > 1) return
                  return (
                    <li>
                      <Link
                        to={menuItem.Href}
                        isSticky={
                          this.state.stickyClass === "topSticky" ? true : false
                        }
                        activeStyle={{ color: " #69b051" }}
                      >
                        {menuItem.Menu}
                      </Link>
                    </li>
                  )
                })}
                <li>
                  <NavDropdown
                    id="test"
                    title={
                      <span
                        className="dropbtn"
                        isSticky={
                          this.state.stickyClass === "topSticky" ? true : false
                        }
                      >
                        Services{" "}
                      </span>
                    }
                  >
                    <div className="dropdwn">
                      <Link to="/custom-home">Custom Homes</Link>
                      <Link to="/remodelling">Remodelling and Renovations</Link>
                      <Link to="/architecture">
                        Architecture & Interior Design
                      </Link>

                      <Link to="/concierge">Signature Consierge</Link>
                      <Link to="/">Rellocating to The Gambia</Link>
                    </div>
                  </NavDropdown>
                </li>
                {this.props.HeaderData.MenuItems.map((menuItem, idx) => {
                  if (idx < 2) return null

                  return (
                    <li>
                      <Link
                        to={menuItem.Href}
                        isSticky={
                          this.state.stickyClass === "topSticky" ? true : false
                        }
                        activeStyle={{ color: " #69b051" }}
                      >
                        {menuItem.Menu}
                      </Link>
                    </li>
                  )
                })}
                <li>
                  <AnchorLink
                    className="contactBtn"
                    offset={55}
                    href={this.props.HeaderData.ContactBtn.Href}
                  >
                    {this.props.HeaderData.ContactBtn.Menu}
                  </AnchorLink>
                </li>
              </ScrollSpy>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </NavbarCustom>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        realestatePage1Json {
          Header {
            BrandLogo
            BrandLogoSticky
            Items
            MenuItems {
              Menu
              Href
            }
            ContactBtn {
              Menu
              Href
            }
          }
        }
      }
    `}
    render={data => (
      <Headermenu HeaderData={data.realestatePage1Json.Header} {...props} />
    )}
  />
)
