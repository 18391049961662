import styled from "styled-components"
import {
  SectionSubHeading,
  SectionHeading,
  Commonh5,
  Commonpara,
  Commonbtn,
} from "../Common/common.style"
import { device } from "../Common/device"
import { SocialFacebook } from "@styled-icons/typicons/SocialFacebook"
import { SocialTwitter } from "@styled-icons/typicons/SocialTwitter"
import { SocialLinkedin } from "@styled-icons/typicons/SocialLinkedin"
import { SocialInstagram } from "@styled-icons/typicons/SocialInstagram"

export const FooterSection = styled.section`
  background: #f5f5f5;
`

export const FooterBg = styled.img`
  max-height: 500px;
  width: 100%;
`

export const FooterWrapper = styled.div`
  @media ${device.tablet} {
    padding: 0px 10px;
    object-fit: cover;
  }
`

export const ContactWrapper = styled.div`
  display: flex;
  margin-top: -100px;
  box-shadow: 0px 0px 30px 0px #ddd;

  @media ${device.laptop} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    margin-top: -80px;
  }
  @media ${device.mobileL} {
    margin-top: -50px;
  }
`

export const ContactLayout = styled.div`
  padding: 40px;
  background: #fff;
  width: 75%;

  @media ${device.laptop} {
    width: 100%;
  }
`

export const HeadingLayout = styled.div`
  margin-bottom: 40px;
`

export const Heading = styled(SectionHeading)`
  margin-bottom: 0px;
`

export const SubHeading = styled(SectionSubHeading)``

export const Form = styled.form`
  margin-bottom: 0;
`
export const FormLayout = styled.div``

export const FormGroup = styled.div`
  margin-bottom: 30px;
`

export const InputText = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  padding: 8px 0px;
  outline: 0;
  font-size: 15px;
`

export const ContactSpanErr = styled.span`
  color: red;
  font-size: 14px;
`

export const TextareaMessage = styled.textarea`
  width: 100%;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  padding: 8px 0px;
  height: 100px;
  font-size: 15px;
  outline: 0;
`

export const TextCenter = styled.div`
  text-align: left;
`

export const SubmitBtn = styled(Commonbtn)`
  font-size: 15px;
`

export const ContactSpanSuccess = styled.span`
  color: green;
  font-size: 14px;
`

export const ContactUsLayout = styled.div`
  padding: 40px;
  background: #69b051;
  width: 30%;

  @media ${device.laptop} {
    width: 100%;
  }
`
export const ContactUsSubHeading = styled(Commonh5)`
  margin-bottom: 0px;
  color: #fff;
  margin-bottom: 40px;
`

export const DetailsLayout = styled.div`
  display: flex;
  align-items: flex-start;
`

export const DetailsIcon = styled.img`
  width: 20px;
  flex-shrink: 0;
`

export const DetailsText = styled(Commonpara)`
  margin-left: 10px;
  color: #fff;
`

export const SocialLayout = styled.div``

export const FooterSocial = styled.a`
  margin-right: 10px;
`

export const InstaIcon = styled(SocialInstagram)`
  width: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px;
  border-radius: 100%;
  margin: 10px 0px 0px;
  transition: all 0.5s;
  :hover {
    background: #ed008d;
    color: #fff;
  }
`
export const LinkedinIcon = styled(SocialLinkedin)`
  width: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  padding: 6px;
  border-radius: 100%;
  margin: 10px 0px 0px;
  transition: all 0.5s;
  :hover {
    background: #3b5999;
    color: #fff;
  }
`
export const TwitterIcon = styled(SocialTwitter)`
  width: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  padding: 6px;
  margin: 10px 0px 0px;
  transition: all 0.5s;
  :hover {
    background: #00adee;
    color: #fff;
  }
`
export const FbIcon = styled(SocialFacebook)`
  width: 32px;
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  padding: 6px;
  margin: 10px 0px 0px;
  transition: all 0.5s;
  :hover {
    background: #3b5999;
    color: #fff;
  }
`

export const FooterBottomLayout = styled.div`
  max-width: 800px;
  text-align: center;
  margin: auto;
`

export const CompanyLogo = styled.img`
  width: 300px;
  margin-left: -20px;
  margin-bottom: 20px;
  margin-top: 60px;
`

export const AboutText = styled(Commonpara)``

export const QuickLinksLayout = styled.div`
  margin-bottom: 20px;
`

export const FooterUl = styled.ul`
  text-align: center;
  margin: 0;
  padding: 10px 0px;
  background: #eee;
`

export const FooterLi = styled.li`
  display: inline-block;
  margin-left: 10px;
  margin: 0px 20px;

  a {
    color: #333;
  }
`

export const CopyrightsText = styled(Commonpara)`
  margin-bottom: 40px;
  text-align: center;
`
export const CopyrightsAnchor = styled.a`
  color: #545454;
  text-decoration: none;

  :hover {
    text-decoration: none;
    color: #545454;
  }
`
