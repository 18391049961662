const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileT: "485px",
  mobileXL: "576px",
  tablet: "768px",
  laptop: "991px",
  laptopM: "1199px",
  laptopN: "1200px",
  laptopL: "1440px",
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileT: `(max-width: ${size.mobileT})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopN: `(max-width: ${size.laptopN})`,
  laptopL: `(max-width: ${size.laptopL})`,
  minlaptopL: `(min-width: ${size.laptopL})`,
}
