import styled from "styled-components"
import { device } from "./device"

export const Commonbtn = styled.a`
  background: #69b051;
  color: #fff;
  padding: 10px 40px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;

  :hover {
    background: #5bcd34;
    text-decoration: none;
    color: #fff;
  }

  @media ${device.mobileXL} {
    padding: 8px 30px;
  }
`

export const Commonh1 = styled.h1`
  font-size: 48px;
  line-height: 54px;
  margin-bottom: 20px;
  color: #282828;

  @media ${device.laptop} {
    font-size: 42px;
    line-height: 48px;
  }

  @media ${device.mobileXL} {
    font-size: 38px;
    line-height: 44px;
  }
`

export const Commonh2 = styled.h2`
  font-size: 42px;
  line-height: 48px;
  margin-bottom: 20px;
  color: #282828;

  @media ${device.tablet} {
    font-size: 40px;
    line-height: 46px;
  }
`

export const Commonh3 = styled.h3`
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 30px;
  color: #282828;
  text-transform: uppercase;
`

export const Commonh4 = styled.h4`
  font-size: 30px;
  color: #282828;
  line-height: 36px;
  margin-bottom: 20px;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 32px;
  }
`

export const Commonh5 = styled.h5`
  font-size: 22px;
  color: #282828;
  line-height: 28px;
  margin-bottom: 20px;
`

export const Commonh6 = styled.h6`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #282828;

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.mobileXL} {
    font-size: 17px;
    line-height: 23px;
  }
`

export const CommonMulih6 = styled.h6`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
  color: #282828;
  font-family: "Muli", sans-serif;

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.mobileXL} {
    font-size: 17px;
    line-height: 23px;
  }
`

export const Commonpara = styled.p`
  color: #545454;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1.5;
`

export const SectionHeading = styled(Commonh2)`
  text-align: left;
  line-height: 1;

  @media ${device.tablet} {
    line-height: 1;
  }
`

export const SectionSubHeading = styled(Commonh5)`
  line-height: 1;
  margin-bottom: 5px;
  text-transform: uppercase;
`
